export const letitrain = `
Frau Jane
Würden wir uns jemals wiedersehen
Die Zeiten, die wir hatten, werden Tag für Tag
Der ganze Schmerz
Dass wir uns gegenseitig bezahlt haben
Nicht genug, um uns falsch zu machen
Würde es diese Liebe für immer verkaufen
Wenn man wieder an der Kreuzung steht, werden viele Dinge in der Liebe wachsen
Langsam im Gange
Ich weiß - du musst auf das Telefon hören
Lass es regnen, lass es regnen
Bis die Sonne wieder zurückkommt
Die Antwort ist dein einziger Freund

`

export const imagine = `
Stell dir vor, es gibt keinen Himmel
Es ist einfach, wenn du es versuchst
Keine Hölle unter uns
Über uns nur Himmel
Stell dir die ganzen Leute vor
Lebe für heute
Ah
Stellen Sie sich vor es gibt keine Länder
Es ist nicht schwer zu tun
Nichts unwiderstehliches
Und auch keine Religion
Stell dir die ganzen Leute vor
Leben in Frieden
Sie
Sie können sagen, ich bin ein Träumer
Aber ich bin nicht der Einzige
ich hoffe, du wirst uns eines Tages beitreten
Und die Welt wird eins sein

`

export const allbymyself = `
Als ich jung war
Ich habe nie jemanden gebraucht
Und Liebe machen war nur zum Spaß
Diese Zeiten sind vorrüber

Allein leben
Ich denke an all die Freunde, die ich gekannt habe
Aber wenn ich das Telefon wähle
Niemand ist zuhause

Ganz alleine
Will nicht mehr alleine sein
Ganz alleine
Ich will nicht mehr alleine lebene

`