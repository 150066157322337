import React from 'react';
import { FooterStyle } from './styles';

const Footer = () => {
    return (
            <FooterStyle>
                Munx Und Liv ©
            </FooterStyle>
    );
};

export default Footer;