import styled from "styled-components";

export const NavStyle = styled.div`
    width: 100vw;
    height:3em;
    position: fixed;
    top:0;
    z-index:2500;
    background-color: rgb(32, 35, 42);
    color: #f7f7f7;
    display: flex;
    a{
        top:0;
        left:20px;
        padding: 10px;
    }
    .nav-active{
        &::after{
            content:"";
            height: 2px;
            background: #ff0000;
            display:flex;
            justify-content: center;
            align-items: center;
        }
    }
`