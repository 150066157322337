import styled from "styled-components";

export const FooterStyle = styled.div`
position:fixed;
bottom: 0;
left:0;
width:100%;
height:2em;
z-index: 0;
text-align: center;
background-color: rgb(32, 35, 42);
color: #f7f7f7;
`