import React from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom"
import About from './pages/About';
import NotFound from './pages/NotFound';
import Question from './pages/Question';



const App = () => {
  
  return (
    <BrowserRouter>
    <Switch>
    <Route path="/a-propos/" exact component={About} />
      <Route path="/" component={Question} />
      <Route component={NotFound} />
    </Switch>
    </BrowserRouter>
  );
};

export default App;