import styled from "styled-components";

export const NavStyle = styled.div`
position:fixed;
display:flex;
flex-direction: column;
align-items: center;
justify-content: space-around;
left:0;
width:3.1vw;
height:100vh;
z-index: 2500;
text-align: center;
background-color: rgb(32, 35, 42);
color: #f7f7f7;
font-size: 1.1vh;

`

export const LiStyle = styled.div`
&:hover{
        color: #f700ff;
        transform: scale(1.5);
        }
`