
export const defaultNumStyle = {
    hide:{opacity:'0'},
    visible:{opacity:'1'}
}

export const defaultQuestionStyle = {
    questionCont:{},
    questionContReveal:{marginTop:'0px'},
    normal:{fontSize: '1.5em'},
    onReveal:{fontSize: '1.5em'},
    image:{},
    imageReveal:{}
}

export const defaultTextStyle = {
    normal: {color:'black'},
    normalHide: {opacity: '0', filter:'blur(100px)'},
    onReveal:{
        true: {color:'#00daff', textDecoration: 'blink',minWidth:'450px'},
        false: {color:'red', textDecoration: 'line-through'},
        unHide: {opacity:'1',filter:'none'}
    }
}

export const defaultAudioStyle = {
    normalHide: {opacity: '0',filter:'blur(100px)'},
    onReveal:{
        unHide: {opacity:'1',filter:'none'}
    }
}