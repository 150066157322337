import React from 'react'
import Footer from '../components/Footer/Footer'
import Navigation from '../components/Navigation/Navigation'
import { quest } from '../assets/questions' // Questions input
import Pages from '../components/Pages/Pages'
import NavbarQuestions from '../components/NavbarQuestions/NavbarQuestions'

const Question = () => {
  return (
    <div id='container'>
      <Navigation />
      <NavbarQuestions props={quest.questions} />
      {
            quest.questions.map((child, index) => {
              child.index = index
              return (
                <Pages props={child} key={child.text} />
              )
            })
}
      <Footer />
    </div>
  )
}

export default Question
