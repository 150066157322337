import React, { useEffect, useState } from 'react'
import Toggle from 'react-toggle'
import eventBus from '../EventBus'

export const GreenMode = () => {

  const [isGreen, setIsGreen] = useState(true)

  useEffect(() => {
    eventBus.dispatch("greenToggle", { greenToggle: isGreen });
  }, [isGreen])

  return (
    <Toggle
      className='GreenToggle'
      checked={isGreen}
      onChange={(toggleEvent) => setIsGreen(toggleEvent.target.checked)}
      icons={{ checked: 'Green', unchecked: '' }}
      // icons={{ checked: '🌙', unchecked: '🔆' }}
      aria-label='Green mode'
    />
  )
}
