import React from 'react'
import { GreenMode } from '../Elements/GreenMode/GreenMode'
// import { NavLink } from 'react-router-dom'
import { NavStyle } from './styles'
import { RevealMode } from '../Elements/RevealMode/RevealMode'

const Navigation = () => {
  return (
      <NavStyle>

      {/* <NavLink exact to='/' activeClassName='nav-active'>
        Quizz
      </NavLink> 
      
      <NavLink exact to='/Reponses' activeClassName='nav-active'>
        Reponses
      </NavLink>
    
       <NavLink exact to="/a-propos" activeClassName="nav-active">
         A propos
       </NavLink> */}
      
        <RevealMode/>
       <GreenMode/>
       
       </NavStyle>
  )
}

export default Navigation