import styled from "styled-components";

//Utilisé pour la pages de réponses
export const PageStyle = styled.section`
    width: 100vw;
    height: 100vh;
    left:3vw;
    position: relative;
    padding:0;
    box-sizing: border-box;
    // scroll-snap-align: center;
    transition: background-color 1s;
`
//Utilisé pour les Questions
export const QuestionCatStyle = styled.div`
position:absolute;
color: #dadada;
top:3em;
font-weight: bold;
transition: all 1s;
`
export const QuestionStyle = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%;
    max-height:50vh;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    object-fit: cover;
    
    cursor: default;

    background-color: rgba(127,127,255,1);
    border: 2px outset yellow;
    box-shadow: 1px 3px 5px rgba(0,0,0,0.7);
    border-radius:1em;
    transition: all 1s;

    `
export const QuestionTextStyle = styled.div`
    text-align: center;
    transition: all 1s;

    `
export const QuestionImgStyle = styled.img`
    text-align: center;
    object-fit: cover;
    height: 45vh;
    width: 50vw;
    transition: all 1s;
`
export const QuestionAudioStyle = styled.audio`
    width:80%;
    text-align: center;
    transition: all 1s;

`
export const QuestionVideoStyle = styled.video`
    width:80%;
    text-align: center;
    transition: all 1s;

`

//Utilisé pour les reponses
export const AnswersStyle = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 90vw;
    // height:20%;
    margin-top:auto;
    margin-bottom:auto;
    margin-left:5%; 
    transition: all 1s;

`
export const AnswersNumStyle = styled.div`
    position:absolute;
    width: 30px;
    text-align: center;
    background-color: blue;
    color:red;
    border-radius:5em;
    box-shadow: 1px 1px 5px red;
    `
export const AnswersTextStyle = styled.h2`
    // display: flex;
    // justify-content: space-evenly;
    text-align: center;
    background-color: rgba(127,127,255,1);
    border: 2px outset yellow;
    border-radius:1em;
    width: 60vw;
    margin-top: 2em;
    min-width: 410px;
    transition: all 1s;
    `
    //LATER.............................................
    export const AnswersImgContStyle = styled.div`
    width: 80vw;
    height: 50vh;
    text-align: center;
    overflow: hidden;
    margin-top: 10px;
    transition: all 1s;
`
export const AnswersImgStyle = styled.img`
    width: 50%;
    height: 15em;
    text-align: center;
    object-fit: cover;
    transition: all 1s;
`
export const AnswersAudioStyle = styled.audio`
    width:80%;
    text-align: center;
    transition: all 1s;

`
export const AnswersVideoStyle = styled.video`
    width:80%;
    text-align: center;
    transition: all 1s;
`