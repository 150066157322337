import React, { useState, useEffect } from 'react'
import { LiStyle, NavStyle } from './styles'
import Scroll, { Link } from 'react-scroll'
const scroller = Scroll.scroller


function retnum (str) {
  const num = str.replace(/[^0-9]/g, '')
  return parseInt(num, 10)
}

const NavbarQuestions = (props) => {
  const [pagesNum] = useState(props.props)
  const [activeTab, setActiveTab] = useState(0)

  const handleKey = (e) => {
    const activeTabIndex = retnum(activeTab)
    if (e.keyCode === 40) {
      scroller.scrollTo(`question_${activeTabIndex + 1}`, { smooth: true, duration: 500 })
    }
    if (e.keyCode === 38) {
      scroller.scrollTo(`question_${activeTabIndex - 1}`, { smooth: true, duration: 500 })
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKey)
    return () => window.removeEventListener('keydown', handleKey)
  }, [activeTab])

  const handleSetActive = (e) => {
    setActiveTab(e)
  }
  return (
    <NavStyle className='navbar'>
      <br />
      <ul>
        {
                    pagesNum.map((child, index) => {
                      return (
                        <Link
                          activeClass='active'
                          to={'question_' + index}
                          spy
                          smooth
                          onSetActive={handleSetActive}
                          key={'question_' + index}
                          onKeyDown={() => console.log('keyy donw')}
                          duration={500}
                        >
                          <LiStyle>{index}</LiStyle>

                        </Link>
                      )
                    })
                }
      </ul>
    </NavStyle>
  )
}

export default NavbarQuestions
