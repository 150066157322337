import React from 'react';
import Footer from '../components/Footer/Footer';
import Navigation from "../components/Navigation/Navigation";

const NotFound = () => {
    return (
        <>
        <Navigation/>
        <div>
            <h1>
               404
            </h1>
        </div>
        <Footer/>
        </>
    );
};

export default NotFound;<h1>
Erreur 404</h1>