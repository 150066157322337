export const categories = {
    AKA :{
        name:"A.K.A",
        style:{backgroundColor:'#6B1010', color:'white'}
    },
    werbinich :{
        name:"Wer bin ich?",
        style:{backgroundColor:'#52246B'}
    },
    funfacts :{
        name:"Funfacts",
        style:{backgroundColor:'#6B5C68'}
    },
    Geo :{
        name:"hesch e Fänschterplatz gha?",
        style:{backgroundColor:'#6B5441'}
    },
    thommymaria :{
        name:"Thommymaria",
        style:{backgroundColor:'#6B6043'}
    },
    werweissdennsowas :{
        name:"Wer weiss denn sowas?",
        style:{backgroundColor:'#6B2453'}
    },
    werhatsgesagt :{
        name:"Wer hats gesagt?",
        style:{backgroundColor:'#30486B'}
    },
    fruehling :{
        name:"Frühling oder lug mau & was isch fr Zyt?",
        style:{backgroundColor:'#3C7000'}
    },
    diedreiaufsohr :{
        name:"Die 3 aufs Ohr",
        style:{backgroundColor:'#007049'}
    },
    sport:{
        name:"Spocht",
        style:{backgroundColor:'#A0323F'}
    },
    uebelsetzungen:{
        name:"Übelsetzungen",
        style:{backgroundColor:'#5C3130'}
    },
    KKK:{
        name:"Karäs krassi Kategorie",
        style:{backgroundColor:'#B3A252'}
    },
    intros:{
        name:"Filmmusik/ Serien Intros",
        style:{backgroundColor:'#7BB67E'}
    },
    nmm:{
        name:"NMM",
        style:{backgroundColor:'#B6B387'}
    },
    letsschetz:{
        name:"let's Schetz",
        style:{backgroundColor:'#5E9AB3'}

}
}