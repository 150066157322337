import React, { useEffect, useState } from 'react'
import eventBus from '../Elements/EventBus'
import Voice from '../Elements/Voice/Voice'
import {
  PageStyle,
  QuestionStyle, QuestionTextStyle, QuestionImgStyle,
  AnswersStyle, AnswersTextStyle, AnswersImgStyle,
  QuestionAudioStyle, AnswersAudioStyle,
  QuestionVideoStyle, AnswersVideoStyle, QuestionCatStyle,
  AnswersImgContStyle,
  AnswersNumStyle
} from './styles'

const randomColor = () => {
  const COLOR = []
  for (let i = 0; i < 3; i++) {
    const val = Math.round(Math.random() * 150)
    COLOR.push(val)
  }
  return COLOR
}

const Pages = (props) => {
  const [FORMULAIRE] = useState(props.props)
  const [random_color] = useState({ backgroundColor: `rgba(${randomColor()[0]},${randomColor()[1]},${randomColor()[2]})` })
  const [green_color] = useState({ backgroundColor: 'rgba(0,255,0,1)' })
  const [backgroundStyle, setBackgroundStyle] = useState(green_color)
  const [reveal, setReveal] = useState(false)
  const alph = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J']

  const callbackGreen = (data) => data?.greenToggle ? setBackgroundStyle(green_color) : setBackgroundStyle(random_color)
  const callbackReveal = (data) => data?.revealToggle ? setReveal(true) : setReveal(false)

  useEffect(() => {
    eventBus.on('greenToggle', callbackGreen)
    eventBus.on('revealToggle', callbackReveal)
    return () => {
      eventBus.remove('greenToggle', callbackGreen)
      eventBus.remove('revealToggle', callbackReveal)
    }
  }, [])

  return (
    <PageStyle id={'question_' + FORMULAIRE.index} className='pages' style={backgroundStyle}>

      {FORMULAIRE.categorie ? <QuestionCatStyle style={FORMULAIRE.categorie.style}> {FORMULAIRE.categorie.name}</QuestionCatStyle> : false}

      <br /><br /><br /><br />

      {FORMULAIRE.question
        ? <QuestionStyle style={reveal?FORMULAIRE.question.questionStyleReveal:FORMULAIRE.question.questionStyle}>

          {FORMULAIRE.question.text ? <QuestionTextStyle style={reveal ? FORMULAIRE.question.styleReveal : FORMULAIRE.question.style}> {FORMULAIRE.question.text} </QuestionTextStyle> : false}
          {FORMULAIRE.question.image ? <QuestionImgStyle style={reveal ? FORMULAIRE.question.styleImageReveal : FORMULAIRE.question.styleImage} src={FORMULAIRE.question.image} alt={FORMULAIRE.question.alt} /> : false}
          {FORMULAIRE.question.audio ? <QuestionAudioStyle style={reveal ? FORMULAIRE.question.styleReveal : FORMULAIRE.question.style} controls> <source src={FORMULAIRE.question.audio} /> </QuestionAudioStyle> : false}
          {FORMULAIRE.question.video ? <QuestionVideoStyle style={reveal ? FORMULAIRE.question.styleReveal : FORMULAIRE.question.style} controls> <source src={FORMULAIRE.question.video} /> </QuestionVideoStyle> : false}
          {FORMULAIRE.question.voice ? <Voice style={reveal ? FORMULAIRE.question.styleReveal : FORMULAIRE.question.style} props={FORMULAIRE.question.voice} /> : false}

        </QuestionStyle>
        : false}

      {FORMULAIRE.reponses
        ? <AnswersStyle>
          {FORMULAIRE.reponses.map((child, index) => {
            return (
              <>
                {child.text ? <AnswersTextStyle style={reveal ? child.styleReveal : child.style}> <AnswersNumStyle style={child.numStyle}> {alph[index]} </AnswersNumStyle> <div>{child.text}</div> </AnswersTextStyle> : false}
                {child.image ? <AnswersImgContStyle><AnswersImgStyle style={reveal ? child.styleReveal : child.style} src={child.image} alt={child.alt} /></AnswersImgContStyle> : false}
                {child.audio ? <AnswersAudioStyle style={reveal ? child.styleReveal : child.style} controls> <source src={child.audio} /> </AnswersAudioStyle> : false}
                {child.video ? <AnswersVideoStyle style={reveal ? child.styleReveal : child.style} controls> <source src={child.video} /> </AnswersVideoStyle> : false}
                {child.voice ? <Voice style={reveal ? child.styleReveal : child.style} props={child.voice} /> : false}

              </>
            )
          })}
        </AnswersStyle>
        : false}

    </PageStyle>
  )
}

export default Pages
