import React, { useEffect, useState } from 'react'
import Toggle from 'react-toggle'
import eventBus from '../EventBus'

export const RevealMode = () => {

  const [isReveal, setIsReveal] = useState()

  useEffect(() => {
    eventBus.dispatch("revealToggle", { revealToggle: isReveal });

  }, [isReveal])

  return (
    <Toggle
      className='RevealToggle'
      checked={isReveal}
      onChange={(toggleEvent) => setIsReveal(toggleEvent.target.checked)}
      icons={{ checked: 'Reveal', unchecked: '' }}
      // icons={{ checked: '🌙', unchecked: '🔆' }}
      aria-label='Reveal mode'
    />
  )
}
