import React from 'react';
import Footer from '../components/Footer/Footer';
import Navigation from '../components/Navigation/Navigation';

const About = () => {
    return (
        <div>
            <Navigation/>
            <h1>A propos</h1>
            <br/>
            <p>
            Passionné par les quizz<br/>
            </p>
            <Footer/>
        </div>
    );
};

export default About;