import React, { useState } from 'react';
import { ButtonStyle } from './styles';



    

const Voice = (props) => {
    const [text] = useState(props.props.text)
    const [lang] = useState(props.props.lang)
    const [playing, setPlaying] = useState(false)

    const lecture_du_texte = (language,nom) => {
        const message = new SpeechSynthesisUtterance(nom);
        message.lang = language;
        console.log(window.speechSynthesis.getVoices());
        window.speechSynthesis.speak(message);
        setPlaying(true)
        }

        const stop_playing = () => {
            window.speechSynthesis.cancel();
            setPlaying(false)
        }

    const handleClick = () => {
        console.log('Cliqué' + text);
        !playing?lecture_du_texte(lang, text):stop_playing()
      }    

    return (
        <div>
            <ButtonStyle type='button' onClick={handleClick}/>      
        </div>
    );
};

export default Voice;