import { categories } from "./categories"
import { defaultAudioStyle, defaultNumStyle, defaultQuestionStyle, defaultTextStyle, pageStyle } from "./styles"
import { allbymyself, imagine, letitrain } from "./texts"



export const quest = {
    questions:[
        //************************************************* */
        {
            //text, images, video, audio, voice
            categorie: categories['AKA'],
            question:{
                text:"A. Wovon sprechen die Deutschen, wenn sie von Porree sprechen?",
                style: defaultQuestionStyle.normal,
                styleReveal: defaultQuestionStyle.onReveal,
                questionStyle: defaultQuestionStyle.questionCont,
                questionStyleReveal: defaultQuestionStyle.questionContReveal
                    },
            reponses:[
                {
                    text: 'Lauch',
                    numStyle: defaultNumStyle.hide,
                    style: defaultTextStyle.normalHide,
                    styleReveal: defaultTextStyle.onReveal.unHide
                }
            ]
        },
        //************************************************* */
{
            
            categorie: categories['AKA'],
            question:{
                text:"B. In der Schweiz macht man einen französischen Abgang, welchen Abgang macht man in Deutschland?",
                style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal,
                    questionStyle: defaultQuestionStyle.questionCont,
                questionStyleReveal: defaultQuestionStyle.questionContReveal
                    },
            reponses:[
                {
                    text: 'Einen polnischen Abgang oder einen französischen Abgang',
                    numStyle: defaultNumStyle.hide,
                    style: defaultTextStyle.normalHide,
                    styleReveal: defaultTextStyle.onReveal.unHide
                }
            ]
        },
        //************************************************* */
{

            categorie: categories['AKA'],
            question:{
                text:"C. Wer ist die schwedische Queen Elizabeth?",
                style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal,
                    questionStyle: defaultQuestionStyle.questionCont,
                questionStyleReveal: defaultQuestionStyle.questionContReveal
                    },
            reponses:[
                {
                    text: 'Silvia von Schweden',
                    numStyle: defaultNumStyle.hide,
                    style: defaultTextStyle.normalHide,
                    styleReveal: defaultTextStyle.onReveal.unHide
                }
            ]
        },
        //************************************************* */
{

            categorie: categories['AKA'],
            question:{
                text:"D. Wie heisst der Gott im Islam?",
                style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal,
                    questionStyle: defaultQuestionStyle.questionCont,
                questionStyleReveal: defaultQuestionStyle.questionContReveal
                    },
            reponses:[
                {
                    text: 'Allah',
                    numStyle: defaultNumStyle.hide,
                    style: defaultTextStyle.normalHide,
                    styleReveal: defaultTextStyle.onReveal.unHide
                }
            ]
        },
        //************************************************* */

{

            categorie: categories['werbinich'],
            question:{
                text:"A.",
                image:"./media/werBinIch_2/01_Simonetta_Somaruga.jpg",
                styleImage: defaultQuestionStyle.image,
                styleImageReveal: defaultQuestionStyle.imageReveal,
                style: defaultQuestionStyle.normal,
                styleReveal: defaultQuestionStyle.onReveal
                    },
            reponses:[
                {
                    text: 'Simonetta Sommaruga',
                    numStyle: defaultNumStyle.hide,
                    style: defaultTextStyle.normalHide,
                    styleReveal: defaultTextStyle.onReveal.unHide
                }
            ]
        },
        //************************************************* */
{

            categorie: categories['werbinich'],
            question:{
                text:"B.",
                image:"./media/werBinIch_2/02_Roger_Federer.jpg",
                styleImage: defaultQuestionStyle.image,
                styleImageReveal: defaultQuestionStyle.imageReveal,
                style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal
                    },
            reponses:[
                {
                    text: 'Roger Federer',
                    numStyle: defaultNumStyle.hide,
                    style: defaultTextStyle.normalHide,
                    styleReveal: defaultTextStyle.onReveal.unHide
                }
            ]
        },
        //************************************************* */
  

{

            categorie: categories['werbinich'],
            question:{
                text:"C.",
                image:"./media/werBinIch_2/03_Lara_Gut.jpg",
                styleImage: defaultQuestionStyle.image,
                styleImageReveal: defaultQuestionStyle.imageReveal,
                style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal
                    },
            reponses:[
                {
                    text: 'Lara Gut- Berahmi',
                    numStyle: defaultNumStyle.hide,
                    style: defaultTextStyle.normalHide,
                    styleReveal: defaultTextStyle.onReveal.unHide
                }
            ]
        },
        //************************************************* */
{

            categorie: categories['werbinich'],
            question:{
                text:"D.",
                image:"./media/werBinIch_2/04_Kurt_Aeschbacher.jpg",
                styleImage: defaultQuestionStyle.image,
                styleImageReveal: defaultQuestionStyle.imageReveal,
                style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal
                    },
            reponses:[
                {
                    text: 'Kurt Äschbi',
                    numStyle: defaultNumStyle.hide,
                    style: defaultTextStyle.normalHide,
                    styleReveal: defaultTextStyle.onReveal.unHide
                }
            ]
        },
        //************************************************* */

{

            categorie: categories['funfacts'],
            question:{
                text:"1. Wie oft kann man ein Blatt Papier, egal welcher Grösse, maximal falten?",
                style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal
                    },
            reponses:[
                {
                    text: '5x',
                    style: defaultTextStyle.normal,
                    styleReveal: defaultTextStyle.onReveal.false
                },
{
                    text: '11x',
                    style: defaultTextStyle.normal,
                    styleReveal: defaultTextStyle.onReveal.false
                },
{
                    text: '7x',// true
                    style: defaultTextStyle.normal,
                    styleReveal: defaultTextStyle.onReveal.true
                }
            ]
        },
        //************************************************* */
{

            categorie: categories['funfacts'],
            question:{
                text:"2. Welches Tier ist das einzige, dass würfelförmig stuhlt/kackt/knattert/gagelt?",
                style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal,
                    questionStyle: defaultQuestionStyle.questionCont,
                questionStyleReveal: defaultQuestionStyle.questionContReveal
                    },
            reponses:[
                {
                    text: 'Wombat',
                    numStyle: defaultNumStyle.hide,
                    style: defaultTextStyle.normalHide,
                    styleReveal: defaultTextStyle.onReveal.unHide
                }

            ]
        },
        //************************************************* */

{

            categorie: categories['funfacts'],
            question:{
                text:"3. Wie viele Herzen haben Oktopusse?",
                style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal,
                    questionStyle: defaultQuestionStyle.questionCont,
                questionStyleReveal: defaultQuestionStyle.questionContReveal
                    },
            reponses:[
                {
                    text: '3',
                    numStyle: defaultNumStyle.hide,
                    style: defaultTextStyle.normalHide,
                    styleReveal: defaultTextStyle.onReveal.unHide
                }

            ]
        },
        //************************************************* */

{

            categorie: categories['funfacts'],
            question:{
                text:"4. Über wieviele Zimmer verfügt der Buckingham Palace in London?",
                style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal
                    },
            reponses:[
                {
                    text: '775 Zimmer, davon 78 Badezimmer',// true
                    style: defaultTextStyle.normal,
                    styleReveal: defaultTextStyle.onReveal.true
                },
{
                    text: '381 Zimmer, davon 28 Badezimmer',
                    style: defaultTextStyle.normal,
                    styleReveal: defaultTextStyle.onReveal.false
                },
{
                    text: '157 Zimmer, davon 17 Badezimmer',
                    style: defaultTextStyle.normal,
                    styleReveal: defaultTextStyle.onReveal.false
                }
            ]
        },
        //************************************************* */

{

            categorie: categories['Geo'],
            question:{
                text:"1. Durch wie viele Länder fliesst die Donau vom Ursprung bis zur Meeresmündung?",
                style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal,
                    questionStyle: defaultQuestionStyle.questionCont,
                questionStyleReveal: defaultQuestionStyle.questionContReveal
                    },
            reponses:[
                {
                    text: '10', // evtl. Picture
                    numStyle: defaultNumStyle.hide,
                    style: defaultTextStyle.normalHide,
                    styleReveal: defaultTextStyle.onReveal.unHide
                }
            ]
        },
        //************************************************* */

{

            categorie: categories['Geo'],
            question:{
                text:"2. Durch wie viele Länder fliesst der Rhein vom Ursprung bis zur Meeresmündung?",
                style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal,
                    questionStyle: defaultQuestionStyle.questionCont,
                questionStyleReveal: defaultQuestionStyle.questionContReveal
                    },
            reponses:[
                {
                    text: '6', // evtl. Picture
                    numStyle: defaultNumStyle.hide,
                    style: defaultTextStyle.normalHide,
                    styleReveal: defaultTextStyle.onReveal.unHide
                }
            ]
        },
        //************************************************* */
{

            categorie: categories['Geo'],
            question:{
                text:"3. In welcher Sprache heisst Visu Labu «Tschüss»?",
                style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal,
                    questionStyle: defaultQuestionStyle.questionCont,
                questionStyleReveal: defaultQuestionStyle.questionContReveal
                    },
            reponses:[
                {
                    text: 'Lettisch', // evtl. Picture
                    numStyle: defaultNumStyle.hide,
                    style: defaultTextStyle.normalHide,
                    styleReveal: defaultTextStyle.onReveal.unHide
                }
            ]
        },
        //************************************************* */
{

            categorie: categories['Geo'],
            question:{
                text:"4. a. Welche Flagge gehört wohin? ",
                image: "./media/geo_4/a_Transflagge.png",
                styleImage: defaultQuestionStyle.image,
                styleImageReveal: defaultQuestionStyle.imageReveal,
		        style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal
                    },
            reponses:[
                {
                    text: 'Transflagge', 
                    numStyle: defaultNumStyle.hide,
                    style: defaultTextStyle.normalHide,
                    styleReveal: defaultTextStyle.onReveal.unHide
                }
            ]
        },
        //************************************************* */
  {

            categorie: categories['Geo'],
            question:{
                text:"4. b. Welche Flagge gehört wohin? ",
                image: "./media/geo_4/c_Ungarn.png",
                styleImage: defaultQuestionStyle.image,
                styleImageReveal: defaultQuestionStyle.imageReveal,
		        style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal
                    },
            reponses:[
                {
                    text: 'Ungarn', 
                    numStyle: defaultNumStyle.hide,
                    style: defaultTextStyle.normalHide,
                    styleReveal: defaultTextStyle.onReveal.unHide
                }
            ]
        },
        //************************************************* */
{

            categorie: categories['Geo'],
            question:{
                text:"4. c. Welche Flagge gehört wohin? ",
                image: "./media/geo_4/b_nigeria.jpg",
                styleImage: defaultQuestionStyle.image,
                styleImageReveal: defaultQuestionStyle.imageReveal,
		        style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal
                    },
            reponses:[
                {
                    text: 'Nigeria', 
                    numStyle: defaultNumStyle.hide,
                    style: defaultTextStyle.normalHide,
                    styleReveal: defaultTextStyle.onReveal.unHide
                }
            ]
        },
        //************************************************* */

{

            categorie: categories['thommymaria'],
            question:{
                text:" Hollywood oder Sunnega? ",
                // image: "Thomi 1",
		        style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal
                    },
            reponses:[
                { 
		    // image: "Thomi 1", // and all the other pictures voilavoia// Pictures are cropped, only shoes or clothes first, then reveal,can you put them all on 1 page? like a collage?
                    style: defaultTextStyle.normal,
                    styleReveal: defaultTextStyle.onReveal.false
                }
            ]
        },
        //************************************************* */
{

            categorie: categories['werweissdennsowas'],
            question:{
                text:"1. In der Schweiz leben so viele intergeschlechtliche Menschen, wie….? ",
		style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal
                    },
            reponses:[
                { 
		    text: 'Bäuer:innen',// true
                    style: defaultTextStyle.normal,
                    styleReveal: defaultTextStyle.onReveal.true
                },

		{ 
		    text: 'Astrophysikerin:innen',
                    style: defaultTextStyle.normal,
                    styleReveal: defaultTextStyle.onReveal.false
                },

		{ 
		    text: 'Elektriker:innen ',
                    style: defaultTextStyle.normal,
                    styleReveal: defaultTextStyle.onReveal.false
                }
            ]
        },
        //************************************************* */

{

            categorie: categories['werweissdennsowas'],
            question:{
                text:"2. Was befand sich früher an dem Ort, wo heute die Siloah Haltestelle des «blauen Bähndli» ist?",
		style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal,
                    questionStyle: defaultQuestionStyle.questionCont,
                questionStyleReveal: defaultQuestionStyle.questionContReveal
                    },
            reponses:[
                { 
		    text: 'der Pestfriedhof',
            numStyle: defaultNumStyle.hide,        
            style: defaultTextStyle.normalHide,
                    styleReveal: defaultTextStyle.onReveal.unHide
                }

		
            ]
        },
        //************************************************* */

{

            categorie: categories['werweissdennsowas'],
            question:{
                text:"3. Der höchste Berg der Welt ist der Mount Everest: Wie hoch ist er? ",
		        style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal
                    },
            reponses:[
                { 
		        text: "9'019 M.ü.M.",
                    style: defaultTextStyle.normal,
                    styleReveal: defaultTextStyle.onReveal.false
                },

		{ 
		    text: "8’848 M.ü.M.",// true
                    style: defaultTextStyle.normal,
                    styleReveal: defaultTextStyle.onReveal.true
                },

		{ 
		    text: "8'371 M.ü.M",
                    style: defaultTextStyle.normal,
                    styleReveal: defaultTextStyle.onReveal.false
                }
            ]
        },
        //************************************************* */
        {
            
                        categorie: categories['werweissdennsowas'],
                        question:{
                            text:"4. Welches bekannte und traditionsreiche Schweizer Produkt feierte im Jahr 2018 seinen 110. Geburi? ",
                    style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal
                                },
                        reponses:[
                            { 
                        text: 'Das Schweizer Sackmesser',
                        style: defaultTextStyle.normal,
                        styleReveal: defaultTextStyle.onReveal.false
                            },
            
                    { 
                        text: 'Die Toblerone',// true
                        style: defaultTextStyle.normal,
                        styleReveal: defaultTextStyle.onReveal.true
                            },
            
                    { 
                        text: 'Der Caran d’Ache-Farbstift',
                        style: defaultTextStyle.normal,
                        styleReveal: defaultTextStyle.onReveal.false
                            }
                        ]
                    },
                    //************************************************* */

            
            {
            
                        categorie: categories['werhatsgesagt'],
                        question:{
                            text:"a. Weil Gesundheit auch Hautsache ist ",
                    style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal,
                    questionStyle: defaultQuestionStyle.questionCont,
                questionStyleReveal: defaultQuestionStyle.questionContReveal
                                },
                        reponses:[
                            { 
                        text: 'Vichy',
                        numStyle: defaultNumStyle.hide,        
                        style:defaultTextStyle.normalHide,
                        styleReveal:defaultTextStyle.onReveal.unHide
                            }
            
                    
                        ]
                    },
        //************************************************* */
        {
            
                        categorie: categories['werhatsgesagt'],
                        question:{
                            text:"b.Just do it  ",
                    style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal,
                    questionStyle: defaultQuestionStyle.questionCont,
                questionStyleReveal: defaultQuestionStyle.questionContReveal
                                },
                        reponses:[
                            { 
                        text: 'Nike',
                        numStyle: defaultNumStyle.hide,        
                        style:defaultTextStyle.normalHide,
                                styleReveal:defaultTextStyle.onReveal.unHide
                            }
            
                    
                        ]
                    },
                    //************************************************* */
            
            {
            
                        categorie: categories['werhatsgesagt'],
                        question:{
                            text:"c.Nichts ist unmöglich  ",
                    style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal,
                    questionStyle: defaultQuestionStyle.questionCont,
                questionStyleReveal: defaultQuestionStyle.questionContReveal
                                },
                        reponses:[
                            { 
                        text: "Toyota",
                        numStyle: defaultNumStyle.hide,
                        style:defaultTextStyle.normalHide,
                        styleReveal:defaultTextStyle.onReveal.unHide
                            }
            
                    
                        ]
                    },
                    //************************************************* */
            
            {
            
                        categorie: categories['werhatsgesagt'],
                        question:{
                            text:"d. Wohnst du noch oder lebst du schon?  ",
                    style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal,
                    questionStyle: defaultQuestionStyle.questionCont,
                questionStyleReveal: defaultQuestionStyle.questionContReveal
                                },
                        reponses:[
                            { 
                        text: "Ikea",
                        numStyle: defaultNumStyle.hide,
                        style:defaultTextStyle.normalHide,
                        styleReveal:defaultTextStyle.onReveal.unHide
                            }
            
                    
                        ]
                    },
                    //************************************************* */
            {
            
                        categorie: categories['werhatsgesagt'],
                        question:{
                            text:"e.Ich bin doch nicht blöd   ",
                    style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal,
                    questionStyle: defaultQuestionStyle.questionCont,
                questionStyleReveal: defaultQuestionStyle.questionContReveal
                                },
                        reponses:[
                            { 
                        text: "Media Markt",
                        numStyle: defaultNumStyle.hide,
                        style:defaultTextStyle.normalHide,
                        styleReveal:defaultTextStyle.onReveal.unHide
                            }
            
                    
                        ]
                    },
                    //************************************************* */
            {
            
                        categorie: categories['fruehling'],
                        question:{
                            text:"1. a.Wie heisse ich?  ",
                    image: "./media/fruhling_82/a_Krokusse.jpg",//see picture 8. Frühling  Frage 1
                    styleImage: defaultQuestionStyle.image,
                styleImageReveal: defaultQuestionStyle.imageReveal,
                    style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal
                                },
                        reponses:[
                            { 
                        text: "Krokus",
                        numStyle: defaultNumStyle.hide,
                        style:defaultTextStyle.normalHide,
                        styleReveal:defaultTextStyle.onReveal.unHide
                            }
            
                    
                        ]
                    },
                    //************************************************* */
            {
            
                        categorie: categories['fruehling'],
                        question:{
                            text:"1. b.Wie heisse ich?  ",
                    image: "./media/fruhling_82/b_Narzissen.jpg",//see picture 8. Frühling  Frage 1
                    styleImage: defaultQuestionStyle.image,
                styleImageReveal: defaultQuestionStyle.imageReveal,
                    style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal
                                },
                        reponses:[
                            { 
                        text: "Narzisse",
                        numStyle: defaultNumStyle.hide,
                        style:defaultTextStyle.normalHide,
                        styleReveal:defaultTextStyle.onReveal.unHide
                            }
            
                    
                        ]
                    },
                    //************************************************* */
            {
            
                        categorie: categories['fruehling'],
                        question:{
                            text:"1. c.Wie heisse ich?  ",
                    image: "./media/fruhling_82/c_Primeln.jpg", //see picture 8. Frühling  Frage
                    styleImage: defaultQuestionStyle.image,
                styleImageReveal: defaultQuestionStyle.imageReveal,
                    style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal
                                },
                        reponses:[
                            { 
                        text: "Primeln",
                        numStyle: defaultNumStyle.hide,
                        style:defaultTextStyle.normalHide,
                        styleReveal:defaultTextStyle.onReveal.unHide
                            }
            
                    
                        ]
                    },
                    //************************************************* */
            {
            
                        categorie: categories['fruehling'],
                        question:{
                            text:"2. a.An welchen Baum gehöre ich?  ",
                    image: "./media/fruhling_8/a_apfelbluten.jpg",//see picture 8. Frühling  Frage 2
                    styleImage: defaultQuestionStyle.image,
                styleImageReveal: defaultQuestionStyle.imageReveal,
                    style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal
                                },
                        reponses:[
                            { 
                        text: "Apfelbaum",
                        numStyle: defaultNumStyle.hide,
                        style: defaultTextStyle.normalHide,
                    styleReveal: defaultTextStyle.onReveal.unHide
                            }
            
                    
                        ]
                    },
                    //************************************************* */
            {
            
                        categorie: categories['fruehling'],
                        question:{
                            text:"2. b.An welchen Baum gehöre ich?  ",
                    image: "./media/fruhling_8/b_lindenbluten.jpg",//see picture 8. Frühling  Frage 2
                    style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal
                                },
                        reponses:[
                            { 
                        text: "Linde",
                        numStyle: defaultNumStyle.hide,
                        style: defaultTextStyle.normalHide,
                    styleReveal: defaultTextStyle.onReveal.unHide
                            }
            
                    
                        ]
                    },
                    //************************************************* */
            {
            
                        categorie: categories['fruehling'],
                        question:{
                            text:"2. c.An welchen Baum gehöre ich?  ",
                    image: "./media/fruhling_8/c_kirschbluten.jpg",//see picture 8. Frühling  Frage 2
                    styleImage: defaultQuestionStyle.image,
                styleImageReveal: defaultQuestionStyle.imageReveal,
                    style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal
                                },
                        reponses:[
                            { 
                        text: "Kirschenbaum",
                        numStyle: defaultNumStyle.hide,
                        style: defaultTextStyle.normalHide,
                    styleReveal: defaultTextStyle.onReveal.unHide
                            }
            
                    
                        ]
                    },
                    //************************************************* */
            {
            
                        categorie: categories['fruehling'],
                        question:{
                            text:"3. Letztes Jahr feierte die Sommerzeit in der Schweiz ein Jubiläum. Zum wievielten Mal jährte sich die Zeitumstellung?  ",
                    style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal
                                },
                        reponses:[
                            { 
                        text: "30 Jahre",
                                style: defaultTextStyle.normal,
                                styleReveal: defaultTextStyle.onReveal.false
                            },
             { 
                        text: "40 Jahre",// true
                                style: defaultTextStyle.normal,
                                styleReveal: defaultTextStyle.onReveal.true
                            },
             { 
                        text: "50 Jahre",
                                style: defaultTextStyle.normal,
                                styleReveal: defaultTextStyle.onReveal.false
                            }
            
                    
                        ]
                    },
                    //************************************************* */
            {
            
                        categorie: categories['diedreiaufsohr'],
                        question:{
                            text:"A hallo wer ist da?",
                            audio:"./audio/die_3_aufs_Ohr/Alain_Berset_Hingerzi.mp3",// see 9.die 3 aufs Ohr
                            style: defaultQuestionStyle.normal,
                            styleReveal: defaultQuestionStyle.onReveal,
                            questionStyle: defaultQuestionStyle.questionCont,
                questionStyleReveal: defaultQuestionStyle.questionContReveal
                                },
                        reponses:[
                            { 
                        audio: "./audio/die_3_aufs_Ohr/Alain_Berset_Furezi.mp3",
                                style: defaultAudioStyle.normalHide,
                                styleReveal: defaultAudioStyle.onReveal.unHide
                            }
                        ]
                    },
                    //************************************************* */
            {
            
                        categorie: categories['diedreiaufsohr'],
                        question:{
                            text:"B hallo wer ist da?",
                            audio:"./audio/die_3_aufs_Ohr/Großer_Zapfenstreich_Rede_von_Kanzlerin_Merkel_hingerzi.mp3",// see 9.die 3 aufs Ohr
                    style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal,
                    questionStyle: defaultQuestionStyle.questionCont,
                questionStyleReveal: defaultQuestionStyle.questionContReveal
                                },
                        reponses:[
                            { 
                        audio: "./audio/die_3_aufs_Ohr/Großer_Zapfenstreich_Rede_von_Kanzlerin_Merkel_furezi.mp3",
                        style: defaultAudioStyle.normalHide,
                        styleReveal: defaultAudioStyle.onReveal.unHide
                            }
             
            
                    
                        ]
                    },
                    //************************************************* */
            {
            
                        categorie: categories['diedreiaufsohr'],
                        question:{
                            text:"C hallo wer ist da?",
                            audio:"./audio/die_3_aufs_Ohr/Yan_Sommer_Hingerzi.mp3",// see 9.die 3 aufs Ohr
                    style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal,
                    questionStyle: defaultQuestionStyle.questionCont,
                questionStyleReveal: defaultQuestionStyle.questionContReveal
                                },
                        reponses:[
                            { 
                        audio: "./audio/die_3_aufs_Ohr/Yan_Sommer_Furezi.mp3",
                        style: defaultAudioStyle.normalHide,
                        styleReveal: defaultAudioStyle.onReveal.unHide
                            }
             
            
                    
                        ]
                    },
                    //************************************************* */
            
            {
            
                        categorie: categories['sport'],
                        question:{
                            text:"1. Welche der folgenden Sportarten ist keine Kampfsportart?  ",
                    style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal
                                },
                        reponses:[
                            { 
                        text: "Wushu",
                                style: defaultTextStyle.normal,
                                styleReveal: defaultTextStyle.onReveal.false
                            },
                    { 
                        text: "Fuzal",// true
                                style: defaultTextStyle.normal,
                                styleReveal: defaultTextStyle.onReveal.true
                            },
                    { 
                        text: "Budo",
                                style: defaultTextStyle.normal,
                                styleReveal: defaultTextStyle.onReveal.false
                            },
                    { 
                        text: "Savate",
                                style: defaultTextStyle.normal,
                                styleReveal: defaultTextStyle.onReveal.false
                            },
                    { 
                        text: "Sambo",
                                style: defaultTextStyle.normal,
                                styleReveal: defaultTextStyle.onReveal.false
                            }
            
                    
                        ]
                    },
                    //************************************************* */
            {
            
                        categorie: categories['sport'],
                        question:{
                            text:"2. Wie hoch sind die Hürden für Frauen bei 100m Rennen?  ",
                    style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal,
                    questionStyle: defaultQuestionStyle.questionCont,
                questionStyleReveal: defaultQuestionStyle.questionContReveal
                                },
                        reponses:[
                            { 
                        text: "84cm",
                        numStyle: defaultNumStyle.hide,
                        style:defaultTextStyle.normalHide,
                        styleReveal:defaultTextStyle.onReveal.unHide
                            }
                    
            
                    
                        ]
                    },
                    //************************************************* */
            
            {
            
                        categorie: categories['sport'],
                        question:{
                            text:"3. Den wievielten Grand-Slam Titel hat sich Nadal bei den Australian Opens 2022 geholt?  ",
                    style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal,
                    questionStyle: defaultQuestionStyle.questionCont,
                questionStyleReveal: defaultQuestionStyle.questionContReveal
                                },
                        reponses:[
                            { 
                        text: "den 21.",
                        numStyle: defaultNumStyle.hide,        
                        style:defaultTextStyle.normalHide,
                                styleReveal:defaultTextStyle.onReveal.unHide
                            }
                    
            
                    
                        ]
                    },
                    //************************************************* */
            
            {
            
                        categorie: categories['uebelsetzungen'],
                        question:{
                            text:"A welches Lied suchen wir?",
                            voice:{
                                text: allbymyself,
                                lang:'de_DE'
                            },// google translate english german link Shinet O`Connor; all by myself
                    style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal,
                    questionStyle: defaultQuestionStyle.questionCont,
                questionStyleReveal: defaultQuestionStyle.questionContReveal
                                },
                        reponses:[
                            { 
                        audio: "./media/uebelsetzungen/Celine_Dion_all_by_my_self.mp3",//Shinet O`Connor; all by myself song
                        style: defaultAudioStyle.normalHide,
                        styleReveal: defaultAudioStyle.onReveal.unHide
                            }
             
            
                    
                        ]
                    },
                    //************************************************* */
            {
            
                        categorie: categories['uebelsetzungen'],
                        question:{
                            text:"B welches Lied suchen wir?",
                            voice:{
                                text: letitrain,
                                lang:'de_DE'
                            },// google translate english german link Gotthard; Let it rain; 
                    style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal,
                    questionStyle: defaultQuestionStyle.questionCont,
                questionStyleReveal: defaultQuestionStyle.questionContReveal
                                },
                        reponses:[
                            { 
                        audio: "./media/uebelsetzungen/Gotthard _Let_It_Rain.mp3",//Gotthard; Let it rain
                        style: defaultAudioStyle.normalHide,
                        styleReveal: defaultAudioStyle.onReveal.unHide
                            }
             
                    
                        ]
                    },
                    //************************************************* */
            {
            
                        categorie: categories['uebelsetzungen'],
                        question:{
                            text:"C welches Lied suchen wir?",
                            voice:{
                                text: imagine,
                                lang:'de_DE'
                            },// google translate english german link john lenon; imagine; 
                    style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal,
                    questionStyle: defaultQuestionStyle.questionCont,
                questionStyleReveal: defaultQuestionStyle.questionContReveal
                                },
                        reponses:[
                            { 
                        audio: "./media/uebelsetzungen/John_Lennon_Imagine.mp3",//John lenon, imagine
                        style: defaultAudioStyle.normalHide,
                        styleReveal: defaultAudioStyle.onReveal.unHide
                            }
             
            
                    
                        ]
                    },
                    //************************************************* */
            
            {
            
                        categorie: categories['KKK'],
                        question:{
                            text:"1. Wie nennt man das Nest eines Eichhörnchens?  ",
                    style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal,
                    questionStyle: defaultQuestionStyle.questionCont,
                questionStyleReveal: defaultQuestionStyle.questionContReveal
                                },
                        reponses:[
                            { 
                        text: "Kobel.",
                        numStyle: defaultNumStyle.hide,
                        style:defaultTextStyle.normalHide,
                        styleReveal:defaultTextStyle.onReveal.unHide
                            }
                    
            
                    
                        ]
                    },
                    //************************************************* */
            
            
            {
            
                        categorie: categories['KKK'],
                        question:{
                            text:"2. Was ist auf sein Leben gerechnet das günstigste Haustier?  ",
                    style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal
                                },
                        reponses:[
                            { 
                        text: "Wellensittich.",
                                style: defaultTextStyle.normal,
                                styleReveal: defaultTextStyle.onReveal.false
                            },
                    { 
                        text: "Aquarienfisch.",
                                style: defaultTextStyle.normal,
                                styleReveal: defaultTextStyle.onReveal.false
                            },
                    { 
                        text: "Hamster.",// true
                                style: defaultTextStyle.normal,
                                styleReveal: defaultTextStyle.onReveal.true
                            }
            
                    
                        ]
                    },
                    //************************************************* */
            
            {
            
                        categorie: categories['KKK'],
                        question:{
                            text:"3. Welcher Vogel war in der Schweiz Vogel des Jahres in 2021?  ",
                    style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal,
                    questionStyle: defaultQuestionStyle.questionCont,
                questionStyleReveal: defaultQuestionStyle.questionContReveal
                                },
                        reponses:[
                            { 
                        text: "Steinkauz",
                        numStyle: defaultNumStyle.hide,
                        style:defaultTextStyle.normalHide,
                        styleReveal:defaultTextStyle.onReveal.unHide
                            }
                    
                    
                        ]
                    },
                    //************************************************* */
            
            {
            
                        categorie: categories['KKK'],
                        question:{
                            text:"4. Wie viele Penisse hat eine Schlange?  ",
                    style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal,
                    questionStyle: defaultQuestionStyle.questionCont,
                questionStyleReveal: defaultQuestionStyle.questionContReveal
                                },
                        reponses:[
                            { 
                        text: "2",
                        numStyle: defaultNumStyle.hide,
                        style:defaultTextStyle.normalHide,
                        styleReveal:defaultTextStyle.onReveal.unHide
                            }
                    
            
                    
                        ]
                    },
                    //************************************************* */
            {
            
                        categorie: categories['KKK'],
                        question:{
                            text:"5. Welches ist das tödlichste Tier?  ",
                    style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal
                                },
                        reponses:[
                            { 
                        text: "Hai.",
                                style: defaultTextStyle.normal,
                                styleReveal: defaultTextStyle.onReveal.false
                            },
                    { 
                        text: "Mücke",// true
                                style: defaultTextStyle.normal,
                                styleReveal: defaultTextStyle.onReveal.true
                            },
                    { 
                        text: "Nilpferd",// true
                                style: defaultTextStyle.normal,
                                styleReveal: defaultTextStyle.onReveal.true
                            }
            
                    
                        ]
                    },
                    //************************************************* */
            {
            
                        categorie: categories['intros'],
                        question:{
                            text:"A",
                            audio:"./audio/Intros/Intro_Maloney.mp3",// see 13. Intros
                    style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal,
                    questionStyle: defaultQuestionStyle.questionCont,
                questionStyleReveal: defaultQuestionStyle.questionContReveal
                                },
                        reponses:[
                            { 
                        text: "a. Maloney",
                        image:"./media/add/maloney.jpg",

                        numStyle: defaultNumStyle.hide,
                        style: defaultTextStyle.normalHide,
                        styleReveal: defaultTextStyle.onReveal.unHide
                            }
             
            
                    
                        ]
                    },
                    //************************************************* */
            {
            
                        categorie: categories['intros'],
                        question:{
                            text:"B",
                            audio:"./audio/Intros/Intro_GZSZ_4_Sek.mp3",// see 13. Intros
                    style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal,
                    questionStyle: defaultQuestionStyle.questionCont,
                questionStyleReveal: defaultQuestionStyle.questionContReveal
                                },
                        reponses:[
                            { 
                        text: "b. GZSZ",
                        image:"./media/add/gzsz.jpg",
                        numStyle: defaultNumStyle.hide,
                        style: defaultTextStyle.normalHide,
                        styleReveal: defaultTextStyle.onReveal.unHide
                            }
             
            
                    
                        ]
                    },
                    //************************************************* */
            
            {
            
                        categorie: categories['intros'],
                        question:{
                            text:"C",
                            audio:"./audio/Intros/Intro_Spiel_mir_das_Lied_vom_Tod.mp3",// see 13. Intros
                    style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal,
                    questionStyle: defaultQuestionStyle.questionCont,
                questionStyleReveal: defaultQuestionStyle.questionContReveal
                                },
                        reponses:[
                            { 
                        text: "c . Spiel mir das Lied vom Tod",
                        image:"./media/add/spiel.jpg",
                        numStyle: defaultNumStyle.hide,
                        style: defaultTextStyle.normalHide,
                        styleReveal: defaultTextStyle.onReveal.unHide
                            }
             
            
                    
                        ]
                    },
                    //************************************************* */
            {
            
                        categorie: categories['intros'],
                        question:{
                            text:"D",
                            audio:"./audio/Intros/Intro_Simpsons.mp3",// see 13. Intros
                    style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal,
                    questionStyle: defaultQuestionStyle.questionCont,
                questionStyleReveal: defaultQuestionStyle.questionContReveal
                                },
                        reponses:[
                            { 
                        text: "d. Simpons",
                        image:"./media/add/simpsons.jpg",
                        numStyle: defaultNumStyle.hide,
                        style: defaultTextStyle.normalHide,
                        styleReveal: defaultTextStyle.onReveal.unHide
                            }
             
            
                    
                        ]
                    },
                    //************************************************* */
            {
            
                        categorie: categories['nmm'],
                        question:{
                            text:"1. Wie viele Liter Wasser passen in einen m3?  ",
                    style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal,
                    questionStyle: defaultQuestionStyle.questionCont,
                questionStyleReveal: defaultQuestionStyle.questionContReveal
                                },
                        reponses:[
                            { 
                        text: " 1000.",
                        numStyle: defaultNumStyle.hide,
                        style:defaultTextStyle.normalHide,
                        styleReveal:defaultTextStyle.onReveal.unHide
                            }
                    
            
                    
                        ]
                    },
                    //************************************************* */
            {
            
                        categorie: categories['nmm'],
                        question:{
                            text:"2. Nach welcher Skala wird die Stärke von Erdbeben angegeben? ",
                    style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal,
                    questionStyle: defaultQuestionStyle.questionCont,
                questionStyleReveal: defaultQuestionStyle.questionContReveal
                                },
                        reponses:[
                            { 
                        text: " Richterskala",
                        numStyle: defaultNumStyle.hide,
                        style:defaultTextStyle.normalHide,
                        styleReveal:defaultTextStyle.onReveal.unHide
                            }
                    
            
                    
                        ]
                    },
                    //************************************************* */
            {
            
                        categorie: categories['nmm'],
                        question:{
                            text:"3.Was ist die Summe der Innenwinkel eines Dreiecks? ",
                    style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal,
                    questionStyle: defaultQuestionStyle.questionCont,
                questionStyleReveal: defaultQuestionStyle.questionContReveal
                                },
                        reponses:[
                            { 
                        text: " 180°",
                        numStyle: defaultNumStyle.hide,
                        style:defaultTextStyle.normalHide,
                        styleReveal:defaultTextStyle.onReveal.unHide
                            }
                    
            
                    
                        ]
                    },
                    //************************************************* */
            
            {
            
                        categorie: categories['letsschetz'],
                        question:{
                            text:"Let's Schetz ",
                    style: defaultQuestionStyle.normal,
                    styleReveal: defaultQuestionStyle.onReveal,
                    questionStyle: defaultQuestionStyle.questionCont,
                questionStyleReveal: defaultQuestionStyle.questionContReveal
                                }
                    }
                    //************************************************* */

    ]}